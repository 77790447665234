// 情感对话

const emotionRouter = [
  {
    path: '/emotionchat',
    name: 'emotion-chat',
    meta: {
      title: "情感陪伴"
    },
    component: () =>
      import('@/views/emotionChat/EmotionChatPage.vue'),
  },

]

export default emotionRouter