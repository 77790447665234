//适配iPad
! function() {
    var docEl = document.documentElement, //返回文档对象的根元素
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function() {
            var clientWidth = document.documentElement.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= '768') {
                docEl.style.fontSize = clientWidth / 15.36 + 'px';
            } else {
                docEl.style.fontSize = clientWidth / 7.5 + 'px';
            }

        };
    if (!document.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
    document.addEventListener('DOMContentLoaded', recalc, false);
}();