const taikangRouter = [
    {
        path: '/tkarchives',
        name: 'tkarchives',
        meta: {
            title: "智能病历夹"
        },
        component: () =>
            import ('@/views/taikang/archives/tkarchives'),
    },
    {
        path: '/archives/:chapters*',
        // path: '/archives',
        name: 'archives',
        meta: {
            title: "智能病历夹"
        },
        component: () =>
            import ('@/views/taikang/archives/archives'),
    },
    {
        path: '/tkcaseOther',
        name: 'tkcaseOther',
        meta: {
            title: "其他"
        },
        component: () =>
            import ('@/views/taikang/archives/caseOther'),
    },
    {
        path: '/tkcaseDetails',
        name: 'tkcaseDetails',
        meta: {
            title: "智能病历夹"
        },
        component: () =>
            import ('@/views/taikang/archives/caseDetails'),
    },
    {
        path: '/summaryDetail',
        name: 'summaryDetail',
        meta: {
            title: "病历详情"
        },
        component: () =>
            import ('@/views/taikang/archives/summary-detail'),
    },
    {
        path: '/treatmentDetails',
        name: 'treatmentDetails',
        meta: {
            title: "智能病历夹"
        },
        component: () =>
            import ('@/views/taikang/archives/treatmentDetails'),
    },
    {
        path: '/treatmenttimelinedetail',
        name: 'treatment-timeline-detail',
        meta: {
            title: "治疗详情"
        },
        component: () =>
            import ('@/views/taikang/archives/treatmentTimelineDetailPage'),
    },

    {
        path: '/tkcase',
        name: 'tkcase',
        meta: {
            title: "我的病历"
        },
        component: () =>
            import ('@/views/taikang/case/case'),
    },
    {
        path: '/newCase',
        name: 'newCase',
        meta: {
            title: "我的病历"
        },
        component: () =>
            import ('@/views/taikang/case/newCase'),
    },

]

export default taikangRouter