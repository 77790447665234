// 用药记录Router
// Created by guoyijie on 2023-08-09.

const medicalRecordRouter = [
  {
    path: '/medicalRecordPatientList',
    name: 'medical-record-patient-list',
    component: () =>
      import('@/views/medicalRecord/MedicalRecordDoctor/MedicalRecordPatientList/medicalRecordPatientList'),
    meta: {
      title: '用药记录',
      
    }
  },
  {
    path: '/medicalRecordPatientMain',
    name: 'medical-record-patient-main',
    component: () =>
      import('@/views/medicalRecord/MedicalRecordDoctor/MedicalRecordPatientMain/medicalRecordPatientMain'),
    meta: {
      title: '用药记录',
      
    }
  },
  {
    path: '/medicalrecordnormaldetail',
    name: 'medical-record-normal-detail',
    component: () =>
      import('@/views/medicalRecord/MedicalRecordDoctor/MedicalRecordNormalDetail/medicalRecordNormalDetail'),
    meta: {
      title: '用药记录',
      
    }
  },
  {
    path: '/medicalRecordabnormaldetail',
    name: 'medical-record-abnormal-detail',
    component: () =>
      import('@/views/medicalRecord/MedicalRecordDoctor/MedicalRecordAbnormalDetail/medicalRecordAbnormalDetail'),
    meta: {
      title: '异常用药记录',
    }
  },
  {
    path: '/mapdemo',
    name: 'map-demo',
    component: () =>
      import('@/views/medicalRecord/MedicalRecordPatient/MapDemo/mapDemo'),
    meta: {
      title: '地图Demo',
      
    }
  },
  {
    path: '/locationlist',
    name: 'location-list',
    component: () =>
      import('@/views/medicalRecord/MedicalRecordPatient/MapDemo/locationList'),
    meta: {
      title: '地点列表',
      
    }
  }
]

export default medicalRecordRouter