// 随诊随访Router
// Created by guoyijie on 2022-10-08.
// 将部分页面的路由改到复查用药提醒中的对应页面 2023-05-22
const followupRouter = [
  {
    path: '/followupIntroduce',
    name: 'introduce',
    meta: {
      title: "智能随诊用药介绍"
    },
    component: () =>
      import('@/views/followup/followup-patient/introducePage/introducePage'),
  },
  {
    path: '/riskQuestionnaire',
    name: 'risk-questionnaire',
    meta: {
      title: "风险问卷"
    },
    component: () =>
      // import('@/views/followup/followup-patient/riskQuestionnairePage/riskQuestionnairePage'),
      import('@/views/RecheckRemind/RecheckRemindPatient/RiskQuestionnairePage/RiskQuestionnairePage'),
  },
  {
    path: '/doctorList',
    name: 'doctor-list',
    meta: {
      title: "随访医生"
    },
    component: () =>
      import('@/views/followup/followup-patient/doctorListPage/doctorListPage'),
  },
  {
    path: '/doctorDetail',
    name: 'doctor-detail',
    meta: {
      title: "医生主页"
    },
    component: () =>
      import('@/views/followup/followup-patient/doctorDetailPage/doctorDetailPage'),
  },
  {
    path: '/followupDetail',
    name: 'followup-detail',
    meta: {
      title: "智能随访用药"
    },
    component: () =>
      // import('@/views/followup/followup-patient/followupDetailPage/followupDetailPage'),
      // 替换到复查用药提醒的详情页面
      import('@/views/RecheckRemind/RecheckRemindPatient/RecheckRemindDetailPage/RecheckRemindDetailPage')
  },
  {
    path: '/imageDetail',
    name: 'image-detail',
    meta: {
      title: "病历详情"
    },
    component: () =>
      import('@/views/followup/followup-patient/imageDetailPage/imageDetailPage'),
  },
  {
    path: '/followupUpload',
    name: 'followup-upload',
    meta: {
      title: "上传病历"
    },
    component: () =>
      // import('@/views/followup/followup-patient/followupUploadPage2/followupUploadPage2'),
      // 替换到复查用药提醒的上传页面
      import('@/views/RecheckRemind/RecheckRemindPatient/RecheckRemindUploadPage/RecheckRemindUploadPage')
  },
  {
    path: '/symptomDescribe',
    name: 'symptom-describe',
    meta: {
      title: "症状描述"
    },
    component: () =>
      import('@/views/followup/followup-patient/symptomDescribePage/symptomDescribePage'),
  },
  {
    path: '/symptomDescribeList',
    name: 'symptom-describe-list',
    meta: {
      title: "症状描述记录"
    },
    component: () =>
      import('@/views/followup/followup-patient/symptomDescribeListPage/symptomDescribeListPage'),
  },
  // {
  //   path: '/followupRemindSetting',
  //   name: 'followup-remind-setting',
  //   meta: {
  //     title: "提醒设置"
  //   },
  //   component: () =>
  //     // import('@/views/followup/followup-patient/followupRemindSettingPage/followupRemindSettingPage'),
  //     import('@/views/RecheckRemind/RecheckRemindPatient/RemindSettingPage/RemindSettingPage'),
  // },
  {
    path: '/propagandaDetailPage',
    name: 'propaganda-detail',
    meta: {
      title: ""
    },
    component: () =>
      import('@/views/followup/followup-patient/propagandaDetailPage/propagandaDetailPage'),
  },
  {
    path: '/myPrivilege',
    name: 'my-privilege',
    meta: {
      title: "我的权益"
    },
    component: () =>
      import('@/views/followup/followup-patient/myPrivilegePage/myPrivilegePage'),
  },
  {
    path: '/createFollowupPlan',
    name: 'create-followup-plan',
    meta: {
      title: "生成随访计划"
    },
    component: () =>
      import('@/views/followup/followup-patient/createFollowupPlanPage/createFollowupPlanPage'),
  },

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // 下面是医生端的路径-----------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------

  {
    path: '/qrcode',
    name: 'qrcode2',
    meta: {
      title: "二维码名片"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/qrcodePage/qrcodePage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/qrcodePage/qrcodePage'),
  },

  {
    path: '/articleCenter',
    name: 'article-center2',
    meta: {
      title: "患教中心"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/articleCenterPage/articleCenterPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/articleCenterPage/articleCenterPage'),
  },
  
  {
    path: '/articleDetail',
    name: 'article-detail3',
    meta: {
      title: "文章详情"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/articleDetailPage/articleDetailPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/articleDetailPage/articleDetailPage'),
  },
  {
    path: '/sendArticle',
    name: 'send-article2',
    meta: {
      title: "发送文章"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/sendArticlePage/sendArticlePage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/sendArticlePage/sendArticlePage'),
  },
  {
    path: '/searchPatient',
    name: 'search-patient2',
    meta: {
      title: "搜索患者"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/searchPatientPage/searchPatientPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/searchPatientPage/searchPatientPage'),
  },
  {
    path: '/patientMain',
    name: 'patient-main2',
    meta: {
      title: "患者主页"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/patientMainPage/patientMainPage'),
  },
  {
    path: '/followupIndex',
    name: 'followup-index2',
    meta: {
      title: "智能随诊随访"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/followupIndexPage/followupIndexPage'),
      // 
      import('@/views/RecheckRemind/RecheckRemindDoctor/followupIndexPage/followupIndexPage'),
  },
  {
    path: '/verifyFollowupPlan',
    name: 'verify-followup-plan2',
    meta: {
      title: "审核随访计划"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/verifyFollowupPlanPage/verifyFollowupPlanPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/verifyFollowupPlanPage/verifyFollowupPlanPage'),
  },
  {
    path: '/editFollowupPlan',
    name: 'edit-followup-plan2',
    meta: {
      title: "编辑随访计划"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/editFollowupPlanPage/editFollowupPlanPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/editFollowupPlanPage/editFollowupPlanPage'),
  },
  {
    path: '/selectInspect',
    name: 'select-inspect3',
    meta: {
      title: "选择检查项目"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/selectInspectPage/selectInspectPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/selectInspectPage/selectInspectPage'),
  },
  {
    path: '/verifyFollowupRecord',
    name: 'verify-followup-record2',
    meta: {
      title: "审核随访记录"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/verifyFollowupRecordPage/verifyFollowupRecordPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/verifyFollowupRecordPage/verifyFollowupRecordPage'),
  },
  {
    path: '/questionnaireList',
    name: 'questionnaire-list2',
    meta: {
      title: "问卷列表"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/questionnaireListPage/questionnaireListPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/questionnaireListPage/questionnaireListPage'),
  },
  {
    path: '/questionnaireDetailPage',
    name: 'questionnaire-detail2',
    meta: {
      title: "问卷详情"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/questionnaireDetailPage/questionnaireDetailPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/questionnaireDetailPage/questionnaireDetailPage'),
  },
  {
    path: '/symptomList',
    name: 'symptom-list2',
    meta: {
      title: "症状描述列表"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/symptomDescribeListPage/symptomDescribeListPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/symptomDescribeListPage/symptomDescribeListPage'),
  },
  {
    path: '/selectPatientTag',
    name: 'select-patient-tag2',
    meta: {
      title: "选择患者类型"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/selectPatientTagPage/selectPatientTagPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/selectPatientTagPage/selectPatientTagPage'),
  },
  {
    path: '/followupRecordReadOnly',
    name: 'select-tag',
    meta: {
      title: "随访记录"
    },
    component: () =>
      // import('@/views/followup/followup-doctor/followupRecordReadOnlyPage/followupRecordReadOnlyPage'),
      import('@/views/RecheckRemind/RecheckRemindDoctor/followupRecordReadOnlyPage/followupRecordReadOnlyPage'),
  },
]

export default followupRouter