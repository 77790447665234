import {
  Toast
} from 'vant'
// 客户端交互方法
if (!window.JSBridge) {
  window.JSBridge = {}
  JSBridge.onUploadMedicalRecord = function(token) {
    console.log('JSBridge.onUploadMedicalRecord token ' + token)
  }

}
