// 临床试验Router
// Created by guoyijie on 2024-02-27.


// http://192.168.31.128:5001/#/clinicaltriallistofpatient?marketingToken=5b0a36a6237c7184c1007ea317fb95d4&patientInquirerId=973
// https://marketing-dev.hospital.foryor-in.com/#/clinicaltriallistofdoctor?qztoken=20216eec1591cef09551&doctorId=272

const clinicalTrialRouter = [
  {
    path: '/clinicaltriallistofpatient',
    name: 'clinical-trial-list-of-patient',
    meta: {
      title: "临床试验"
    },
    component: () =>
      import('@/views/clinicalTrial/clinicalTrialPatient/clinicalTrialListPage/clinicalTrialListPage.vue'),
  },
  {
    path: '/clinicaltrialdetailofpatient',
    name: 'clinical-trial-detail-of-patient',
    meta: {
      title: "临床试验"
    },
    component: () =>
      import('@/views/clinicalTrial/clinicalTrialPatient/clinicalTrialDetailPage/clinicalTrialDetailPage.vue'),
  },
  

  // ------------------------------------------------------------------------
  {
    path: '/clinicaltriallistofdoctor',
    name: 'clinical-trial-list-of-doctor',
    meta: {
      title: "临床试验"
    },
    component: () =>
      import('@/views/clinicalTrial/clinicalTrialDoctor/clinicalTrialListPage/clinicalTrialListPage.vue'),
  },
  {
    path: '/clinicaltrialdetailofdoctor',
    name: 'clinical-trial-detail-of-doctor',
    meta: {
      title: "临床试验"
    },
    component: () =>
      import('@/views/clinicalTrial/clinicalTrialDoctor/clinicalTrialDetailPage/clinicalTrialDetailPage.vue'),
  },
  {
    path: '/clinicaltrialpatientlist',
    name: 'clinical-trial-patient-list',
    meta: {
      title: "临床试验"
    },
    component: () =>
      import('@/views/clinicalTrial/clinicalTrialDoctor/clinicalTrialPatientListPage/clinicalTrialPatientListPage.vue'),
  },
]


export default clinicalTrialRouter
