import Vue from 'vue'
import Vuex from 'vuex'
import { getCookie, setCookie } from '@/util/util'
import {enumList, version} from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: getCookie('token'),
        init: getCookie('init') || null,
        session:getCookie('session'),
        isRiskLevelChanged: false,
        isPrivilegeChanged: false,
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
            setCookie('token', token)
        },
        SET_INIT(state, init) {
            state.init = init
            setCookie('init', init)
        },
        SET_SESSION(state, session) {
            state.session = session
            setCookie('session', session)
        },
        CHANEGE_RISK_LEVEL(state, isRiskLevelChanged) {
            state.isRiskLevelChanged = isRiskLevelChanged
        },
        CHANEGE_PRIVIEGE(state, isPrivilegeChanged) {
            // console.log(isPrivilegeChanged);
            state.isPrivilegeChanged = isPrivilegeChanged
        },
    },
    actions: {
        enumList({ commit },) {
            return new Promise((resolve, reject) => {
                enumList() .then((res) => {
                        let obj = {};
                        res.map((e) => {
                            obj[e.enumType] = e.enumList;
                        });
                        commit('SET_INIT', obj)
                        setCookie('init', obj)
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
    modules: {},
    getters: {
        token: (state) => state.token,
        init: (state) => state.init,
        session: (state) => state.session,
    },
})