

const ctcaeRouter = [
  {
    path: '/ctcae',
    name: 'ctcae',
    meta: {
        title: "不良反应评估"
    },
    component: () =>
        import ('@/views/ctcae/chat/index'),
},
{
  path: '/ctcaetest',
  name: 'ctcae',
  meta: {
      title: "不良反应评估test"
  },
  component: () =>
      import ('@/views/ctcae/chat/index-test'),
},
{
  path: '/ctcaepatientlist',
  name: 'ctcaepatientlist',
  meta: {
      title: "不良反应评估"
  },
  component: () =>
      import ('@/views/ctcae/ctcaePatientListPage/ctcaePatientListPage'),
},
{
  path: '/ctcaepatientmain',
  name: 'ctcaepatientmain',
  meta: {
      title: "不良反应评估"
  },
  component: () =>
      import ('@/views/ctcae/ctcaePatientMainPage/ctcaePatientMainPage'),
},

]

export default ctcaeRouter