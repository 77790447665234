// crypto.js 
// 对crypto-js的封装
// Created by guoyijie on 2022-07-20.

import CryptoJS from 'crypto-js'

function makeKey(key) {
    return CryptoJS.enc.Utf8.parse(key)
  }

export function encryptData(data) {
    const str = CryptoJS.enc.Utf8.parse(data)
    const key = makeKey(sessionStorage.getItem('token'))
    let result = CryptoJS.AES.encrypt(
        str,
        key,
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
    // console.log(result.ciphertext.toString(CryptoJS.enc.Base64))
    return result.ciphertext.toString(CryptoJS.enc.Base64)
}

export function decryptData(data) {
    // console.log(data)
    // console.log(sessionStorage.getItem('token'))
    const key = makeKey(sessionStorage.getItem('token'))
    let decryptResult = CryptoJS.AES.decrypt(
        data,
        key,
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }
    )
    // console.log(decryptResult.toString(CryptoJS.enc.Utf8))
    const result = decryptResult.toString(CryptoJS.enc.Utf8)
    // console.log(result)
    return JSON.parse(result)
}

export default {
    encryptData,
    decryptData
}