import Vue from 'vue'

import Vant, { Toast } from 'vant'
import 'vant/lib/index.css'
import './icons' // icon
import './assets/scss/index.scss'
// import ElementUI from 'element-ui';
import { Table, TableColumn } from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/css/common.css'
import '@/util/jsBridge'


// import iView from 'iview';
import 'iview/dist/styles/iview.css';
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
import FastClick from 'fastclick'
FastClick.attach(document.body);

import App from './App.vue'
import store from './store'
import router from './router'

// import VueWebsocket from "vue-websocket";
// Vue.use(VueWebsocket);
Toast.setDefaultOptions('loading', { forbidClick: true })
Vue.use(Vant)
Vue.use(Toast)
Vue.use(Table)
Vue.use(TableColumn)
// Vue.use(iView);

// Vue.use(Viewer)
// Viewer.setDefaults({
//     Options: {
//         'inline': true, // 启用 inline 模式
//         'button': true, // 显示右上角关闭按钮
//         'navbar': true, // 显示缩略图导航
//         'title': true, // 显示当前图片的标题
//         'toolbar': true, // 显示工具栏
//         'tooltip': true, // 显示缩放百分比
//         'movable': true, // 图片是否可移动
//         'zoomable': true, // 图片是否可缩放
//         'rotatable': true, // 图片是否可旋转
//         'scalable': true, // 图片是否可翻转
//         'transition': true, // 使用 CSS3 过度
//         'fullscreen': true, // 播放时是否全屏
//         'keyboard': true, // 是否支持键盘
//         'url': 'data-source' // 设置大图片的 url
//     }
// })
// Vue.use(ElementUI);


Vue.config.productionTip = false

Vue.mixin({
    mounted: function() {
        // if (this.$route) {
        //   if (this.$route.query.token) {
        //     this.$store.commit('SET_TOKEN', this.$route.query.token)
        //   }
        // }
    },
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')