import request from '@/util/request'
import { formData } from '@/util/util'
//获取
export function requestToken() {
    return request({
        url: `/externalUser/requestToken`,
        method: 'get',
    })
}

// 获取最新的RequestToken
// authType 的可选值有 COST_ESTIMATES, DISEASE_MANAGE, FOLLOW_UP
export function laseRequestToken(authType) {
    return request({
        url: `/externalUser/requestToken/last/${authType}`,
        method: 'get',
    })
}
//查询步骤
export function proceed(requestToken) {
    return request({
        url: `diseaseManageApi/proceed/${requestToken}`,
        method: 'get',
    })
}
//初始化问题
export function diseaseManageApiInit(requestToken) {
    return request({
        url: `/diseaseManageApi/init/${requestToken}`,
        method: 'put',
    })
}
//提交问题
export function diseaseManageApiSubmit(requestToken, data) {
    return request({
        url: `/diseaseManageApi/submit/${requestToken}`,
        method: 'post',
        data
    })
}

//上一题
export function frontQuestion(requestToken, questionCode) {
    return request({
        url: `/diseaseManageApi/frontQuestion/${requestToken}/${questionCode}`,
        method: 'get'
    })
}
//下一题
export function nextQuestion(requestToken, questionCode) {
    return request({
        url: `/diseaseManageApi/nextQuestion/${requestToken}/${questionCode}`,
        method: 'get'
    })
}
//查方案
export function diseaseManageApiPlan(requestToken) {
    return request({
        url: `/diseaseManageApi/plan/${requestToken}`,
        method: 'get'
    })
}


//获取token
export function getToken(data) {
    return request({
        url: `/externalUser/login`,
        method: 'post',
        data
    })
}

// 获取OSS签名信息
export function getOssSign(userId, userRole) {

    let url = `/api/file/getSigned`
    if (userId) {
        if (userRole == 'NURSE') {
            url += `?nurseId=${userId}`
        } else {
            url += `?doctorId=${userId}`
        }
    }

    return request({
        url: url,
        method: 'get',
    })
}
//上传图片
export function submitImage(data) {
    return request({
        url: `/healthRecordApi/submit/image`,
        method: 'post',
        data: formData(data),
    })
}
//查图片列表
export function imageList() {
    return request({
        url: `/healthRecordApi/imageList`,
        method: 'get',
    })
}
//修改图片姓名
export function editImageName(data) {
    return request({
        url: `/healthRecordApi/image`,
        method: 'post',
        data: data,
    })
}

//查询患者基本信息
export function patientBase() {
    return request({
        url: `/healthRecordApi/patient/base`,
        method: 'get',
    })
}
//创建新患者基本信息
export function patientCreate() {
    return request({
        url: `/healthRecordApi/patient/create`,
        method: 'get',
    })
}

//查询患者信息
export function patient() {
    return request({
        url: `/healthRecordApi/patient`,
        method: 'get',
    })
}
//保存患者信息
export function savePatient(data) {
    return request({
        url: `/healthRecordApi/patient`,
        method: 'post',
        data
    })
}
//生成患者档案
export function initPatientInfo() {
    return request({
        url: `/healthRecordApi/initPatientInfo`,
        method: 'post',
    })
}

//
export function summaryAll() {
    return request({
        url: `/healthRecordApi/image/summary/all`,
        method: 'get',
    })
}
//病例档案
export function medicalRecord(orderType) {
    return request({
        url: `/healthRecordApi/medicalRecord?orderType=${orderType}`,
        method: 'get',
    })
}
//获取其他类型
export function medicalRecordOther(orderType) {
    if (orderType) {
        return request({
            url: `/healthRecordApi/medicalRecord/other?orderType=${orderType}`,
            method: 'get',
        })
    } else {
        return request({
            url: `/healthRecordApi/medicalRecord/other`,
            method: 'get',
        })
    }
}
//删除图片
export function deleteImages(data, userId, userRole) {

    let url = `/healthRecordApi/image/id`
    if (userId) {
        if (userRole == 'NURSE') {
            url += `?nurseId=${userId}`
        } else {
            url += `?doctorId=${userId}`
        }
    }

    return request({
        url: url,
        method: 'delete',
        data
    })
}
//修改图片信息
export function modifyMedicalRecord(data) {
    return request({
        url: `/healthRecordApi/medicalRecord`,
        method: 'post',
        data
    })
}
//图片类型init
export function dateType() {
    return request({
        url: `/healthRecordApi/dateType`,
        method: 'get',
    })
}
//查看图片详情
export function imageLayout(imageId) {
    return request({
        url: `/healthRecordApi/image/layout/${imageId}`,
        method: 'get',
    })
}
//修改图片详情
export function imageLayoutEdit(data) {
    return request({
        url: `/healthRecordApi/image/layout`,
        method: 'post',
        data
    })
}
//获取诊断信息
export function imageSummary(imageId, summaryType) {
    return request({
        url: `/healthRecordApi/image/summary/${imageId}/${summaryType}`,
        method: 'get',
    })
}
//保存诊断信息
export function summaryEdit(data) {
    return request({
        url: `/healthRecordApi/image/summary`,
        method: 'post',
        data
    })
}
//删除诊断信息
export function deleteSummary(summaryId) {
    return request({
        url: `/healthRecordApi/image/summary/${summaryId}`,
        method: 'delete',
    })
}

// 获取治疗摘要
// query
// {
//     "diseaseHistory": "",
//     "treatmentName": ""
// }
// 传query则查询具体的治疗摘要
// 不传query则查询全部的治疗摘要
export function treatmentSummary(query) {
    return request({
        url: `/healthRecordApi/treatmentAbstract`,
        method: 'post',
        data: query
    })
}


// ----------------------------------------------------------------


//费用预估
export function costProceed() {
    return request({
        url: `/costEstimatesApi/proceed`,
        method: 'get',
    })
}

export function costFrontQuestion(requestToken, questionCode) {
    return request({
        url: `/costEstimatesApi/frontQuestion/${requestToken}/${questionCode}`,
        method: 'get',
    })
}

export function costNextQuestion(requestToken, questionCode) {
    return request({
        url: `/costEstimatesApi/nextQuestion/${requestToken}/${questionCode}`,
        method: 'get',
    })
}
export function costSubmit(requestToken, data) {
    return request({
        url: `/costEstimatesApi/submit/${requestToken}`,
        method: 'post',
        data
    })
}

export function costUserInfo(requestToken, data) {
    return request({
        url: `/costEstimatesApi/userInfo/${requestToken}`,
        method: 'post',
        data
    })
}
export function therapyPlanInfo(requestToken, therapyStage) {
    return request({
        url: `/costEstimatesApi/therapyPlanInfo/${requestToken}/?therapyType=${therapyStage}`,
        method: 'get',
    })
}
export function therapyPlanType(requestToken, therapyStage) {
    return request({
        url: `/costEstimatesApi/therapyPlanType/${requestToken}/?therapyStage=${therapyStage}`,
        method: 'get',
    })
}
export function costRequestToken(sessionId) {
    let url = '/costEstimatesApi/requestToken'
    if (sessionId) {
        url += '?sessionId=' + sessionId
    }
    return request({
        url: url,
        method: 'get',
    })
}