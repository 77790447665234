// 雀诊服务中心Router
// Created by guoyijie on 2023-02-24.
const qzServiceCenterRouter = [
  {
    path: '/qzscSummaryPage',
    name: 'qzsc-summary-page',
    meta: {
      title: "诊疗摘要"
    },
    component: () =>
      import('@/views/quezhenServiceCenter/summaryPage'),
  },
  {
    path: '/qzscMedicalRecordPage',
    name: 'qzsc-medical-record-page',
    meta: {
      title: "病历档案"
    },
    component: () =>
      import('@/views/quezhenServiceCenter/medicalRecordPage'),
  },
  {
    path: '/qzscFollowupRecordPage',
    name: 'qzsc-followup-record-page',
    meta: {
      title: "随访记录"
    },
    component: () =>
      import('@/views/quezhenServiceCenter/followupRecordPage'),
  },
  {
    path: '/qzscFollowupPlanPage',
    name: 'qzsc-followup-plan-page',
    meta: {
      title: "随访记录"
    },
    component: () =>
      import('@/views/quezhenServiceCenter/followupPlanPage'),
  },
]

export default qzServiceCenterRouter